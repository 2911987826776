.navbar button {
  /*The Team and Our Projects buttons*/
  margin-right: 15px;
  /* background-color: #a7d4e1; */
  background-color: #FEB211;
  font-weight: bold;
  /* color: #16557b; */
  color: #39455D;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 5px;
  padding-bottom: 5px;
  border-radius: 15px;
  border: none;
}

.page-container {
  width: 100%;
  background-color: #BBEBEA;
  /* border: 2px solid rgb(187, 19, 19); */
}

.text-and-photos-container {
  display: flex;
  flex-direction: column;
  /* flex-wrap: wrap; */
}

.all-photos-container {
  /* display: grid; */
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  color: #323b60;
  font-weight: bold;
  /* border: 2px solid rgb(18, 224, 59); */
  gap: 1rem;
  grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  /* margin-top: 2rem;
  margin-inline: 1rem; */
  text-decoration: none;
  margin: 2rem 5% 2% 5%;
}

.volunteersContainer {
  background-color: #CEA0E4;
  margin-top: 1rem;
}
.photo {
  display: flex; /* Use flexbox to position items horizontally */
  /* column-gap: 1rem; */
  align-items: center; /* Center items vertically */
  flex-direction: column;
  cursor: pointer;
  border-color: transparent;
  font-size: 14px;
  text-align: center;
  position: relative;
  /* border: 2px solid rgb(214, 18, 224); */
}

.photo a {
  text-decoration: none;
  color: #323b60;
  margin-bottom: 10px;
}

.photo-volunteers {
  display: flex; /* Use flexbox to position items horizontally */
  /* justify-content: center; Center items horizontally */
  align-items: center; /* Center items vertically */
  flex-direction: column;
  cursor: pointer;
  border-color: transparent;
  font-size: 14px;
  text-align: center;
  position: relative;
  flex: 0 0 20%;
  margin-bottom: 20px;
}

.photo-volunteers a {
  text-decoration: none;
  color: #323b60;
}

.photo-size {
  height: 160px;
}
.text-container {
  display: flexbox;
  flex-direction: column;
  background-color: #BBEBEA;
}
.header {
  margin: 5% 6% 3% 6%;
  font-size: 18px;
  font-weight: bold;
  color: #323b60;
  text-align: center;
}
.pink-line {
  background-color: #50CFCD;
  width: 80%;
  height: 10px;
  border-radius: 25px;
  margin-left: 10%;
}
.text {
  justify-content: space-between;
  margin: 2% 5% 2% 5%;
  font-size: 14px;
  color: #323b60;
  text-align: justify;
}

.coFoundersContainer {
  background-color: #CEA0E4;
}

.volunteersHeader {
  font-size: 20px;
  font-weight: bold;
  color: #323b60;
  text-align: center;
}

.link-our-projects {
  display: flex;
  flex-direction: column;
  background-color: #ABB2E7;
}
.link-text {
  margin-top: 1rem;
  margin-bottom: 1rem;
  font-size: 14px;
  font-weight: bold;
  color: #323b60;
  text-align: center;
  /* background-color: #e5edef; */
  background-color: #ABB2E7;
  border-color: transparent;
}
.footer-container {
  display: flex;
  flex-direction: column;
  /* background-color: #93c7bf; */
  background-color: #50CFCD;
  width: 100%;
  text-align: right;
  @media (min-width: 375px) {
    position: inherit;
  }
}

/* .footer-text {
  border: 0;
  padding-top: 5%;
  font-size: 14px;
  font-weight: bold;
  color: #323b60;
  cursor: pointer;
  background-color: #93c7bf;
} */

.footer-text-copyright {
  border: 0;
  padding-right: 1rem;
  font-size: 12px;
  color: #323b60;
  /* background-color: #93c7bf; */
  background-color: #50CFCD;
}