.all-projects-containerJosh {
  margin-top: 30px;
  margin-left: 50px;
  margin-right: 50px;
  display: flex;
  flex-direction: row;
  justify-content: start;
  border-radius: 20px;
  background-color: #adcfdd;
}

.project-textJosh {
  width: 100%;
  line-height: 1.5;
  font-family: Arial, Helvetica, sans-serif;
  padding-top: 5px;
  padding-right: 20px;
  font-size: 16px;
  text-align: justify;
  justify-content: end;
  color: #052d49;
  margin-right: 20px;
}

.img-container-josh {
  padding: 20px;
  border-radius: 25px;
  height: 200px;
  width: 150px;
}
.img-josh {
  height: 180px;
  border-radius: 17px;
}

.git-emoticon-container {
  width: 1px;
  height: 1px;
  justify-content: flex-end;
  margin-top: -3%;
  margin-left: 0px;
}
.git-child {
  position: relative;
  justify-content: start;
}
.git-emoticon Git {
  max-width: 100%;
  height: 100%;
}