.project-container {
  margin-top: 30px;
  margin-left: 50px;
  margin-right: 50px;
  display: flex;
  flex-direction: row;
  justify-content: start;
  border-radius: 20px;
  background-color: #052d49;

}


.gif-container {
  padding: 20px;
  border-radius: 25px;
  height: 200px;
  width: 150px;
}
.gif {
  height: 180px;
  width: 120px;
}

.project-text {
  width: 100%;

  padding-top: 20px;
  padding-right: 20px;
  font-size: 18px;
  text-align: justify;
  justify-content: end;
  color: #ffffff;
  margin-right: 20px;
  
}

.project-text p {
color: #ffffff;
line-height: 1.5;
font-family: Arial, Helvetica, sans-serif;
}

.project-text h3 {
  color: #ffffff;
  line-height: 1.5;
  font-family: Arial, Helvetica, sans-serif;
}

.git-emoticon-container {
  width: 1px;
  height: 1px;
  justify-content: flex-end;
  margin-top: -3%;
  margin-left: 0px;

}

.git-child {
  position: relative;
  justify-content: start;
}

.git-emoticon Git {
  max-width: 100%;
  height: 100%;
  
}

