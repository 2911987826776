.title {
  font-family: Arial, Helvetica, sans-serif;
  margin-top: 50px;
  text-align: center;
  color: #052d49;
}

.our-projects-container {
  margin-top: 30px;
  margin-left: 50px;
  margin-right: 50px;
  display: flex;
  flex-direction: row;
  /* justify-content: start; */
  border-radius: 20px;
  background-color: #DEBCEF;
  /* margin-bottom: 50px; */
}

.project-textAll {
  width: 100%;
  line-height: 1.5;
  font-family: Arial, Helvetica, sans-serif;
  padding: 5px;
  font-size: 16px;
  text-align: justify;
  justify-content: end;
  color: #052d49;
  /* margin-right: 20px; */
  margin-inline: 5%;
  margin-bottom: 10px;
}

.project-textAll h3 {
  padding: 5px;
}

.img-container-op {
  align-items: center;
  padding: 20px;
  border-radius: 25px;
}
.img-op {
  height: 210px;
  border-radius: 17px;
}

.github-emoticon-container {
  width: 1px;
  height: 1px;
  justify-content: flex-end;
  margin-top: -3%;
  margin-left: 0px;
}
/* .github-child {
  position: relative;
  justify-content: start;
} */

.github-child {
  margin-top: -40px;
  margin-left: 107%;
}
.github-emoticon Git {
  max-width: 100%;
  height: 100%;
}
